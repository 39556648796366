import { compareDates } from "@/utils"
import { mapGetters } from "vuex"
import { ARRAY, FIELD_TYPES } from "@/constants"
import ThemisLogs from "@/components/shared/logs"

export default {
  name      : "IssuePrint",
  components: {
    ThemisLogs
  },
  data: () => ({
    dataToPrint: {}
  }),
  mounted() {
    window.addEventListener("afterprint", this.handleAfterPrint)
    window.setTimeout(() => {
      window.print()
    }, 1000)
  },
  beforeDestroy() {
    window.removeEventListener("afterprint", this.handleAfterPrint)
  },
  computed: {
    ...mapGetters({
      usersIncludingDeletedUsers: "users/usersIncludingDeletedUsers",
      groups                    : "groups/groups",
      issues                    : "issues/issues",
      issueDocuments            : "issueDocuments/issueDocuments",
      statuses                  : "issueStatuses/issueStatuses",
      resolutions               : "issueResolutions/issueResolutions",
      reports                   : "reports/reports",
      languages                 : "languages/languages",
      domains                   : "domains/domains",
      channels                  : "channels/channels",
      messages                  : "messages/messages",
      messageItems              : "messageItems/messageItems",
      translations              : "translations/translations",
      issueFieldValues          : "issueFieldValues/issueFieldValues",
      issueFields               : "issueFields/issueFields",
      fieldsV2                  : "fields/fieldsV2",
      formInstances             : "formInstances/formInstances",
      commentsOfIssues          : "issues/commentsOfIssues",
      logsOfIssues              : "issues/logsOfIssues",
      issueLinks                : "issueLinks/issueLinks"
    }),
    arrayOfIssueIds() {
      return this.issues.map(issue => issue.id)
    },
    logsOfIssue() {
      let logsOfIssue = this.logsOfIssues[this.$route.params.id]
      if (logsOfIssue) {
        logsOfIssue = logsOfIssue.sort((item1, item2) => {
          return compareDates(item2.createdAt, item1.createdAt)
        })
      }
      return logsOfIssue
    },
    commentsOfIssue() {
      let commentsOfIssue = this.commentsOfIssues[this.$route.params.id]
      if (commentsOfIssue.length) {
        commentsOfIssue = commentsOfIssue.sort((item1, item2) => {
          return compareDates(item1.createdAt, item2.createdAt)
        })
      }
      return commentsOfIssue
    },
    domainsMap() {
      const domainsMap = new Object()
      for (const domain of this.domains) {
        domainsMap[domain.id] = domain
      }
      return domainsMap
    },
    languagesMap() {
      const languagesMap = new Object()
      for (const language of this.languages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    resolutionsMap() {
      const resolutionsMap = new Object()
      for (const resolution of this.resolutions) {
        resolutionsMap[resolution.id] = resolution
      }
      return resolutionsMap
    },
    statusesMap() {
      const statusesMap = new Object()
      for (const status of this.statuses) {
        statusesMap[status.id] = status
      }
      return statusesMap
    },
    usersMap() {
      const usersMap = new Object()
      for (const user of this.usersIncludingDeletedUsers) {
        usersMap[user.id] = user
      }
      return usersMap
    },
    groupsMap() {
      const groupsMap = new Object()
      for (const group of this.groups) {
        groupsMap[group.id] = group
      }
      return groupsMap
    },
    translationsMap() {
      const translationsMap = new Object()
      for (const translation of this.translations) {
        translationsMap[translation.messageItemId] = translation
      }
      return translationsMap
    },
    issue() {
      return this.issues?.find(issue => issue.id === +this.$route.params.id)
    },
    reportId() {
      return this.reports?.find(report => report.issueId === this.issue.id)?.id
    },
    report() {
      let report = this.reports?.find(report => report.issueId === this.issue.id)
      if (report) {
        const messages = this.messages?.filter(message =>
          message.reportId === report.id
        )
        report         = Object.assign({}, report, {
          messages
        })
        if (report?.messages?.length && this.messageItems) {
          const indexOfMessages = new Object()
          for (const [index, message] of report.messages.entries()) {
            indexOfMessages[message.id] = index
          }
          for (let messageItem of this.messageItems) {
            const indexOfMessage = indexOfMessages[messageItem.messageId]
            if (indexOfMessage !== undefined) {
              messageItem = Object.assign({}, messageItem, {
                language: this.languagesMap[messageItem.languageId]
              })
              if (report.messages[indexOfMessage].items) {
                report.messages[indexOfMessage].items.push(messageItem)
              } else {
                report.messages[indexOfMessage] = Object.assign({}, report.messages[indexOfMessage], {
                  items: [messageItem]
                })
              }
            }
            if (messageItem.version === "original" && this.translationsMap[messageItem.id]) {
              report.messages[indexOfMessage] = Object.assign({}, report.messages[indexOfMessage], {
                translation: this.translationsMap[messageItem.id]
              })
            }
          }
        }
      }
      return report
    },
    isReportOpen() {
      return this.report?.status === "new"
    },
    isReportAnonymised() {
      return this.report?.anonymisation === "done"
    },
    isReportAnonymisationScheduled() {
      return this.report.anonymisation === "scheduled"
    },
    isAnonymisationNotScheduled() {
      return this.report?.status === "closed" && !this.report.anonymisation
    },
    reportFormInstances() {
      return this.formInstances.filter(formInstance => formInstance.reportId === this.report?.id)
    },
    issueFormInstances() {
      return this.formInstances
        .filter(formInstance => formInstance.issueId === this.issue.id)
        .sort((formInstance1, formInstance2) => {
          return compareDates(formInstance1.createdAt, formInstance2.createdAt)
        })
    },
    documentsOfIssue() {
      return this.issueDocuments?.filter(issueDocument => issueDocument.issueId === +this.$route.params.id)
    },
    documentsOfReport() {
      const documentsOfReport = new Array()
      if (this.report?.messages) {
        for (const message of this.report.messages) {
          if (!message.userId && message.items) {
            for (const item of message.items) {
              if (!["text", "audio"].includes(item.type)) {
                documentsOfReport.push(item)
              }
            }
          }
        }
      }
      return documentsOfReport
    },
    documentsCount() {
      return this.documentsOfIssue.concat(this.documentsOfReport).length
    },
    channel() {
      const channel = this.channels?.find(channel => channel.id === this.report.channelId)
      if (channel) {
        if (channel.overrideTranslationPreference) {
          channel.effectiveTranslationPreference = channel.translationPreferenceId
        } else if (this.globalTranslationPreference) {
          channel.effectiveTranslationPreference = +this.globalTranslationPreference.value
        }
      }
      return channel
    },
    fieldsMap() {
      const fieldsMap = new Map()
      for (const field of this.fieldsV2) {
        fieldsMap.set(field.id, field)
      }
      return fieldsMap
    },
    documentsForDisplaying() {
      const documentsForDisplaying = new Array()
      if (this.documentsOfIssue) {
        for (const document of this.documentsOfIssue) {
          const createdBy = this.getUserName(document.creatorId)
          documentsForDisplaying.push({
            id              : document.id,
            name            : document.name,
            type            : this.$t("656"),
            createdBy,
            createdAt       : this.$moment(document.createdAt).format("DD MMMM YYYY HH:mm (UTCZ)"),
            isReportDocument: false
          })
        }
      }
      if (this.documentsOfReport) {
        for (const document of this.documentsOfReport) {
          documentsForDisplaying.push({
            id              : document.id,
            name            : this.isReportAnonymised ? this.$tc("783") : document.value.name,
            type            : this.$t("657"),
            createdBy       : this.$t("1000"),
            createdAt       : this.$moment(document.createdAt).format("DD MMMM YYYY HH:mm (UTCZ)"),
            isReportDocument: true,
            isRemoved       : this.isReportAnonymised
          })
        }
      }
      return documentsForDisplaying
    },
    issueSection() {
      const issueData = []
      let userName
      issueData.push({ [this.$t("290")]: this.issue.summary })
      issueData.push({ [this.$t("80")]: this.issue.description })
      issueData.push({ [this.$t("1060")]: this.statusesMap[this.issue.statusId].name })
      if (this.issue.assigneeId) {
        const user = this.usersMap[this.issue.assigneeId]
        userName   = user.name
        if (user.disabledButNotDeleted) {
          userName += ` ${this.$t("1131")}`
        }
      } else {
        userName = this.$t("372")
      }
      issueData.push({ [this.$t("291")]: userName })
      issueData.push({ [this.$t("591")]: this.issue.labels ? this.issue.labels.join(", ") : "" })
      issueData.push({ [this.$t("546")]: this.domainsMap[this.issue.domainId].name })
      issueData.push({ [this.$t("639")]: this.formatDateTime(this.issue.receivedAt) })
      issueData.push({ [this.$t("640")]: this.formatDateTime(this.issue.acknowledgedAt) })

      this.dataToPrint[`ID + ${this.issue.id}`] = issueData
      return
    },

    reportOverviewSection() {
      const reportOverviewData = []
      reportOverviewData.push({ [this.$t("468")]: this.channel.name }),
      reportOverviewData.push({ [this.$t("585")]: this.languagesMap[this.report.languageId]?.name ?? "" })
      reportOverviewData.push({ [this.$t("470")]: this.formatDateTime(this.report.updatedAt) })
      reportOverviewData.push({ [this.$t("469")]: this.report.source })
      reportOverviewData.push({ [this.$t("397")]: this.$t("1071") })

      this.dataToPrint[this.$t("1201")] = reportOverviewData
      return
    },

    reportIntakeFormsSection() {
      for (const reporterFormInstance of this.reportFormInstances) {
        const reporterIntakeFormDate = reporterFormInstance.formInstanceFields.map(formInstanceField => {
          const { label, formInstanceFieldValues } = formInstanceField
          const value                              = formInstanceFieldValues[ARRAY.FIRST]?.value || ""
          return { [label]: value }
        })
        this.dataToPrint[`${this.$t("800")}: ${reporterFormInstance.formTemplateName}`] = reporterIntakeFormDate
      }
      return
    },

    issueIntakeFormsSection() {
      for (const issueFormInstance of this.issueFormInstances) {
        const issueFormsData = issueFormInstance.formInstanceFields.map(formInstanceField => {

          const fieldValue = formInstanceField.formInstanceFieldValues[ARRAY.FIRST]?.value || ""
          let value
          switch (formInstanceField.type) {
            case FIELD_TYPES.DATE_TIME.value:
              value = this.formatDateTime(fieldValue)
              break
            case FIELD_TYPES.DATE.value:
              value = this.formatDate(fieldValue)
              break
            case FIELD_TYPES.MULTIPLE_OPTION_LIST.value: {
              const fieldValues = formInstanceField.formInstanceFieldValues.filter(
                formInstanceFieldValue => formInstanceFieldValue.value
              ).map(
                formInstanceFieldValue => formInstanceFieldValue.value
              )

              value = fieldValues.join(", ")
              break
            }
            case FIELD_TYPES.BOOLEAN.value:
              value = fieldValue === "true" ? this.$t("1048") :
                fieldValue === "false" ? this.$t("1049") : ""
              break
            default:
              value = fieldValue
              break
          }

          return { label: formInstanceField.label, value, sortingOrder: formInstanceField.sortingOrder }
        })
        issueFormsData.sort((firstField, secondField) => firstField.sortingOrder - secondField.sortingOrder)
        const sortedIssueFormsData = issueFormsData.map(item => ({ [item.label]: item.value }))

        const key             = `${this.$t("1061")}: ${issueFormInstance.formTemplateName}ID${issueFormInstance.id}`
        this.dataToPrint[key] = sortedIssueFormsData
      }
      return
    },

    moreFieldsSection() {
      const issueFieldsData = this.issueFields.map(issueField => {
        const field      = this.fieldsMap.get(issueField.fieldId)
        const label      = field.label
        const fieldValue = this.issueFieldValues.find(issueFieldValue => issueFieldValue.issueId === this.issue.id &&
          issueFieldValue.issueFieldId === issueField.id)?.value

        let value
        switch (field.type) {
          case FIELD_TYPES.DATE_TIME.value:
            value = this.formatDateTime(fieldValue)
            break
          case FIELD_TYPES.DATE.value:
            value = this.formatDate(fieldValue)
            break
          case FIELD_TYPES.MULTIPLE_OPTION_LIST.value: {
            const fieldValues = this.issueFieldValues.filter(issueFieldValue =>
              issueFieldValue.issueId === this.issue.id &&
              issueFieldValue.issueFieldId === issueField.id &&
              issueFieldValue.value
            ).map(fieldValue => fieldValue.value)

            value = fieldValues.join(", ")
            break
          }
          case FIELD_TYPES.BOOLEAN.value:
            value = fieldValue === "true" ? this.$t("1048") :
              fieldValue === "false" ? this.$t("1049") : ""
            break
          default:
            value = fieldValue
            break
        }

        return { label: label, value: value, sortingOrder: issueField.sortingOrder }
      })
      issueFieldsData.sort((firstField, secondField) => firstField.sortingOrder - secondField.sortingOrder)
      const sortedIssueFieldsData = issueFieldsData.map(item => ({ [item.label]: item.value }))

      sortedIssueFieldsData.push({ [this.$t("299")]: this.formatDateTime(this.issue.createdAt) })
      sortedIssueFieldsData.push({ [this.$t("300")]: this.formatDateTime(this.issue.updatedAt) })

      this.dataToPrint[this.$t("996")] = sortedIssueFieldsData
      return
    },

    linkedIssuesForTheIssue() {
      return this.issueLinks
        .filter(issueLink =>
          (issueLink.fromId === this.issue.id) || (issueLink.toId === this.issue.id)
        )
        .sort((first, second) => {
          return compareDates(second.createdAt, first.createdAt)
        })
    },

    issueLinkSection() {
      const issueLinkData = []

      if (this.linkedIssuesForTheIssue?.length) {
        this.linkedIssuesForTheIssue.forEach(issueLink => {
          if (issueLink.fromId !== this.issue.id &&
            !issueLinkData.some(linkedIssue => linkedIssue.issueId === issueLink.fromId)) {
            if (this.canViewIssueLink(issueLink.fromId)) {
              issueLinkData.push({ [issueLink.fromId]: this.getSummaryForAnIssue(issueLink.fromId) })
            } else {
              issueLinkData.push({ [issueLink.fromId]: ` - ${this.$t("1255")}` })
            }
          } else if (issueLink.toId !== this.issue.id &&
            !issueLinkData.some(linkedIssue => linkedIssue.issueId === issueLink.toId)) {
            if (this.canViewIssueLink(issueLink.toId)) {
              issueLinkData.push({ [issueLink.toId]: this.getSummaryForAnIssue(issueLink.toId) })
            } else {
              issueLinkData.push({ [issueLink.toId]: ` - ${this.$t("1255")}` })
            }
          }
        })
      }

      this.dataToPrint[this.$t("1165", { count: this.linkedIssuesForTheIssue.length })] = issueLinkData
      return
    },

    getDataToPrint() {
      this.issueSection
      this.moreFieldsSection
      if (this.report) {
        this.reportOverviewSection
        this.reportIntakeFormsSection
        this.dataToPrint[this.$t("1132")] = [] // Messages Section
      }
      if (this.documentsCount) {
        this.dataToPrint[this.$t("297", { count: this.documentsCount })] = [] // Documents Section
      }
      this.issueIntakeFormsSection
      if (this.linkedIssuesForTheIssue.length) {
        this.issueLinkSection
      }
      return this.dataToPrint
    }

  },
  methods: {
    getSummaryForAnIssue(issueId) {
      const summary = this.issues.find(issue => issue.id === issueId)?.summary
      return summary ? ` - ${summary}` : ""
    },
    canViewIssueLink(issueId) {
      return this.arrayOfIssueIds.includes(issueId)
    },
    getUserName(userId) {
      let userName
      if (userId) {
        const user = this.usersMap[userId]
        userName   = user.name
        if (user.disabledButNotDeleted) {
          userName += ` ${this.$t("1131")}`
        }
      } else {
        userName = ""
      }
      return userName
    },
    handleAfterPrint() {
      this.$router.push({ name: "issue", params: { id: +this.$route.params.id } })
    },
    isMessageVersionOriginal(version) {
      return version === "original"
    },
    isMessageVersionMachine(version) {
      return version === "machine"
    },
    isMessageVersionHuman(version) {
      return version === "human"
    },
    isMessageTypeText(type) {
      return type === "text"
    },
    formatDateTime(dateTime) {
      if (dateTime) {
        return this.$moment(dateTime).format("D MMMM YYYY HH:mm (UTCZ)")
      }
    },
    formatDate(date) {
      if (date) {
        return this.$moment(date).format("DD MMMM YYYY")
      }
    },
    getLabel(textAlongWithId) {
      const label = textAlongWithId.split("ID")[ARRAY.FIRST]
      return label
    },
    getLabelForDataCy(label) {
      return label.toString().replace(/\s+/g, "_")
    }
  }
}